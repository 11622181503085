.container {
    display: flex;
    flex-direction: column; 
    height: 100vh; 
    /* margin: auto; */
    width: 100vw;
  }
  
.side-by-side {
    display: flex;
    flex-grow: 1; 
    /* This makes the side-by-side container grow to fill available space */
}
  
.form-container {
    position: relative;
}

.error-container {
    width: 100%; /* Full width */
    position: fixed; /* Fixed position */
    top: 0; /* Align to the top of the page */
    left: 0; /* Align to the left */
    z-index: 1000; /* Ensure it's above other elements */

    display: flex;
    flex-direction: column; /* Stack errors vertically */
    align-items: center; /* Center error messages */

    /* Optional styles */
    padding: 10px 0; /* Add some padding at the top and bottom */
    background-color: transparent; /* Background color */
}

.modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: -1; /* Ensure it's behind the modal content */
}

.tos-privacy-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10; /* Adjust as needed */

        /* Optional: Display flex to center the modal dialog */
    display: flex;
    justify-content: center;
    align-items: center;
  }